<template>
  <div id="comment">
    <h1>Quản lý bình luận</h1>
    <p>Coming soon</p>
  </div>
</template>

<script>
export default {
  name: "Post list",
  data() {
    return {
      commentList: [],
    };
  },
  methods: {},
  watch: {},
};
</script>
